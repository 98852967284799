// @import "~bootstrap/scss/bootstrap/variables";
// @import "bootstrap";

@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins";

.DayPicker__horizontal {
    background-color: transparent;
}

.CalendarMonthGrid {
    background-color: transparent;
}

.CalendarDay__default {
    border-color: transparent;
    color: var(--text-color);
    background-color: transparent;
}

.CalendarDay__default:hover {
    border-color: transparent;
    color: var(--text-color);
    background: var(--background-color);
}

.CalendarDay__selected {
    border-color: var(--accent);
}

.CalendarDay__selected:hover {
    background: var(--accent);
}

.CalendarMonth {
    background-color: transparent;
}

.DayPicker_weekHeader {
    color: var(--text-color-secondary)
}

.CalendarMonth_caption {
    color: var(--text-color);
}

.DayPickerNavigation_button__default {
    color: var(--text-color);
    background-color: transparent;
    border-color: #777;
}

.DayPickerNavigation_button__default:active {
    background-color: var(--background-color);
}

.DayPickerNavigation_svg__horizontal {
    fill:  var(--text-color);
}