.App {
  height: 100vh;
  overflow: hidden;
  overflow-y: auto;
  color: var(--text-color);

  display: grid;
  grid-template-rows: 60px 1fr;
  grid-template-columns: 280px 1fr 1.1fr;
  grid-template-areas: 
  "h l d"
  "n l d";
}

.App-header {
  grid-area: h;
  background-color: var(--background-color);
  display: flex;
  align-items: center;

  display: grid;
  grid-template-columns: 2fr 4fr 5fr;
  grid-template-areas: 
  "header-logo header-login"
}

.App-Theme {
  background-color: var(--background-color);
  color: var(--text-color);
}

.dropdown-paddings-horizontal {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.dropdown-paddings {
  padding-left: 1.5rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-right: 1rem;
}

.App-header img {
  height: 40px;
  /* position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%); */
}

.Icon {
  margin-right: 5px;
}

.App-nav {
  grid-area: n;
  background-color: var(--background-color);
  overflow-y: auto;
  height: 100hv;

  /* display: grid;
  grid-template-rows: 3fr 2fr 2fr;
  grid-template-areas: 
  "nav-p"
  "nav-c"
  "nav-t" */
}

.Nav-list {
  grid-area: nav-c;
  background-color: var(--background-color);
  overflow: hidden;
  overflow-y: auto;

}

.App-list {
  grid-area: l;
  display: flex;
  flex-flow: column;
  overflow: hidden;
  height: 100hv;
  background-color: var(--background-color-lighter);
}

.app-theme {
  background-color: var(--accent);
  border-color: var(--accent);
}

.NewTaskInput {
  padding: 0.5em;
}

.TaskItemText {
  width: 100%;
  padding-left: 0.75em;
  padding-right: 0.75em;
    overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  -webkit-box-orient: vertical;
}

.RightMargin {
  margin-right: 0.75em;
}

.Pointer {
  cursor: pointer;
}

.Move {
  cursor: move;
}

.Primary {
  color: var(--text-color);
  font-size: 1em;
}

.Secondary {
  color: var(--text-color-secondary);
  font-size: 0.9em;
}

.nav-collapse {}

.nav-collapse ul {
  padding-left: 0;
}

.nav-collapse-item {
  padding: 5px 10px;
}

.nav-collapse-item:hover {
  color:var(--text-color);
  background-color: var(--background-color-lighter);
}

.list-group {
  border-radius: 0;
}

.list-group-item {
  padding: 0.75em;
  background-color: var(--background-color-lighter);
  color: var(--text-color);
  border: 0px solid;
}

.list-group-item:hover {
  color: var(--text-color);
  background-color:var(--background-color);
}

.selected-task {
  color: var(--text-color);
  background-color: var(--background-color-darker);
  border-color: var(--background-color-darker);
}

.outline-none {
  outline: none;
}

.repeat {
  color: var(--text-color);
}

.list-group-item.active {
  color: var(--text-color);
  background-color: var(--background-color-darker);
  border-color: var(--background-color-darker);
}

.list-group-item-filter {
  padding: 5px 10px;
}
.list-group-item-filter:hover {
  color:var(--text-color);
  background-color: var(--background-color-lighter);
}

.list-group-item-action {
  padding: 5px 10px;
  cursor: pointer;
  color: #aaa;
}

.badge:hover {
  filter:  brightness(80%);
}

.list-group-item-action:hover,
.list-group-item-action.active {
  color: #aaa;
  background-color: var(--background-color-lighter);
}

.list-group-item-action svg {
  max-height: 24px;
}

.list-group-item-action svg:hover {
  fill: #ffffff;
}

.list-group-item-icon-edit {
  opacity: 0;
  transform: translateX(30px);
}

.list-group-item-action:hover .list-group-item-icon-edit {
  opacity: 1;
  transform: translateX(0);
}

.list-group-item-action input[type="text"] {
  padding: 0;
  margin: 0;
  background: none;
  border: 0;
}

.task-details {}

.task-details input[type="text"],
.task-details textarea {
  border-color: transparent;
  background-color: transparent;
  transition-property: border-color, background-color;
  transition-duration: .3s;
  transition-timing-function: ease-in-out;
}

.task-details input[type="text"]:hover,
.task-details textarea:hover {
  border-color: var(--input-border);
  background-color: var(--input-background);
}

.task-details input[type="text"]:focus,
.task-details textarea:focus {
  border-color: var(--input-border-focus);
  background-color: var(--input-background-focus);
}

.task-details input[type="text"] {
  font-size: 1.25em;
}

.TaskTitle {
  font-size: 1em;
}

.subtask {
}

.subtask-dragging {
  //TODO: add transparency
  background-color: var(--background-color);  
  z-index: 10;
}


.subtask input[type="text"] {
  font-size: 1.1em;
}

#all-tags-list {
  margin-left: 1.3em;
}

li {
  list-style-type: none; 
}

ul {
  padding-inline-start: 1.3em;
}

input,
textarea {
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline;
  border: 0;
  outline: 0;
  color: var(--text-color);
  border-color: var(--input-border);
  background-color: var(--input-background);
}

input:focus, textarea:focus {
   border-color: var(--input-border-focus);
  background-color: var(--input-background-focus);
  color: var(--text-color);
}

input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

textarea {
  overflow: auto;
  vertical-align: top;
  
}

input[type="text"] {
  display: block;
  padding: 4px 8px 6px 8px;
  border: 1px solid var(--input-border-focus);
  background: var(--input-background-focus);
}
textarea {
  display: block;
  padding: 4px 8px 6px 8px;
  border: 1px solid var(--input-border-focus);
  background: var(--input-background-focus);
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.nowrap {
  white-space: nowrap ;
}

.TaskCompleted {
  text-decoration: line-through;
}

.TaskSelected {
  background-color: rgb(22, 22, 22);
}

.App-details {
  grid-area: d;
  background-color: var(--background-color);
  /* overflow: hidden;
  overflow-y: auto; */
}

.Scrollable {
  overflow: hidden;
  overflow-y: auto;
}

//scrollbar
::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0px 0px 50px var(--background-color-lighter);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: var(--background-color-lighter);
  -webkit-box-shadow: inset 0 0 6px var(--background-color-very-darker);
}


.btn-circle {
  width: 30px;
  height: 30px;
  padding: 6px 0px;
  border-radius: 15px;
  text-align: center;
  font-size: 12px;
  line-height: 1.42857;
}

.App-details-bar {
  background-color: var(--background-color-lighter);
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.centerContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.centerHorizontal {
  margin-left: auto;
    margin-right: auto;
    display: block;
}

.break {
  flex-basis: 100%;
  height: 0;
}

.overdue {
  color: #F44336;
}

.starred {
  color: #fdd835;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-float infinite 3s ease-in-out;
  }
}

.App-header1 {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: rgb(112, 76, 182);
}

@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px)
  }
  100% {
    transform: translateY(0px)
  }
}
