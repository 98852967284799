.dropdown-item {
    color: var(--text-color);
  }
  
  .dropdown-divider {
    border-top-color: var(--background-color-darker);
  }
  
  .dropdown-item:hover {
    color: var(--text-color);
    background-color: var(--background-color-lighter);
    filter:  brightness(80%);
  }
  
  .dropdown-menu {
    max-height: 600px;
    overflow: hidden;
    overflow-y: auto;
    background-color: var(--background-color-lighter);
  }

  .form-control, .form-control:focus {
    color:var(--text-color);
    border-color: var(--input-border-focus);
    background-color: var(--input-background-focus);
  }

  .modal-content {
    color: var(--text-color);
    background-color: var(--background-color-lighter);
  }

  .modal-header {
    border-color: var(--background-color);
    
    .close {
      color: var(--text-color);
      text-shadow: none;
    }
  }

  .toast-header, .toast-body {
    color: var(--text-color);
    background-color: var(--background-color-darker);
    border-color: var(--background-color-darker);

    .close {
      color: var(--text-color);
      text-shadow: none;
    }
  }

  .modal-footer {
    border-color: var(--background-color);
  }