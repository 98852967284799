@media all and (min-width: 480px) {
    .Login {
      padding: 40px 20px;
      margin: 0px auto;
      max-width: 360px;
      
      color: var(--text-color);
      background-color: var(--background-color);

      border: 1px solid var(--input-border) !important;
      border-radius: 0.2rem !important;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus {
  border: 1px solid  var(--input-border-focus);
  -webkit-text-fill-color: var(--text-color);
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s;
}

.Login input {
  border-color: var(--input-border);
  background-color: var(--input-background);
  color: var(--text-color);
}

.Login input:focus {
  border-color: var(--input-border-focus);
  background-color: var(--input-background-focus);
  color: var(--text-color);
}


.LoginContainer {
  height: 100vh;
  padding: 60px auto;
  background-color: var(--background-color-lighter);
}

.LoginSocialButton {
  border-color: lightgray;
  color: var(--text-color);

}