@import "~bootstrap/scss/bootstrap";

.task {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .icon-desc,
  .icon-repeat,
  .icon-star {
    max-height: 20px;
    max-width: 20px;
  }
}
  
.center-vertically {
  display: flex;
  align-items: center;
}

.alert-enter {
  opacity: 0;
}

.alert-enter-active {
  transition: opacity 500ms ease-out;
  opacity: 1;
}

.alert-exit-active {
  transition: opacity 350ms ease-out;
  opacity: 0;
}

.task-exit-active {
  animation: task 500ms ease-out forwards;
}

.task-enter-active {
  animation: task 500ms ease-in alternate-reverse;
}

@keyframes task {
  0% {
    transform: translateX(0);
  }
  30% {
    opacity: 0.7;
    transform: translateX(30px)
  }

  100% {
    opacity: 0;
    transform: translateX(-200px)
  }
}